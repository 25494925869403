<template>
  <v-card
    :style="
      'width: 100% !important; height: 100% !important; max-height: 100%; overflow-y: scroll; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
    class="pb-4"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module.title }}</v-toolbar-title
      >
    </v-toolbar>
    <e-chart
      v-if="opts.series.length > 0"
      :option="opts"
      style="width: 100%; max-width: 100%; min-height: 100%; max-height: 100%"
      class="pb-3 px-8"
      :autoresize="true"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/markLine";
import "echarts/lib/chart/line";
import "echarts/lib/coord/cartesian/Grid";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/scatter";

import Meta from "@/_helpers/ModuleMeta";
import i18n from "@/plugins/i18n";
import { materialColors } from "@/_helpers/materialDesignColors";

export default {
  name: "FillIndicator",

  mixins: [Meta],

  props: {
    settingsEnabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
    },
    module: {
      default: {},
    },
    tag: {
      default: null,
    },
    timerTick: {
      default: null,
    },
    fakeData: {
      default: false,
    },
  },

  data() {
    return {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24)),
      endDate: new Date(),
      tagData: null,
      keyLabel: "",
      boundAlarm: null,
      alarmHigh: null,
      alarmLow: null,
      colors: ["#5470C6", "#91CC75", "#EE6666"],
      opts: {
        legend: {
          show: true,
          data: [],
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
        },
        yAxis: { type: "value", max: 100 },
        series: [
          {
            data: [60],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.3)",
              borderRadius: 4,
            },
            label: {
              show: true,
              position: "inside",
              color: "#FFFFFF",
            },
            itemStyle: {
              borderRadius: 4,
              color: materialColors[Math.floor(Math.random() * 5 + 1)],
            },
            barMaxWidth: "100%",
            barWidth: "100%",
          },
        ],
        grid: {
          top: 55,
          bottom: 50,
          left: 30,
          right: 30,
        },
      },
    };
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
    ...mapState("alarms", ["currentAlarm"]),
    ...mapState("tag", ["currentLabel"]),
  },

  methods: {
    ...mapActions("tag", ["getKeyLabel"]),
    ...mapActions("tagData", { getData: "getTagDataWithMetrics" }),
    ...mapActions("alarms", ["getAlarmById"]),

    async updateData() {
      let data = await this.getData({
        metrics: {
          tag: this.module.tag.deveui,
          key: this.module.key,
          limit: 1,
        },
      });

      this.setData(data[0].tagDeveui + " - " + this.keyLabel, data[0].value);
      this.updateColors();
    },

    setData(name, value) {
      this.opts.series[0].data = [value];
      this.opts.xAxis.data = [name];

      this.opts.yAxis.min = this.module.min;
      this.opts.yAxis.max = this.module.max;
    },

    setTargetSeries(value) {
      var series = {
        type: "line",
        data: [],
        itemStyle: { color: "#33691e" },
        markLine: {
          data: [{ yAxis: value }],
          silent: true,
          lineStyle: { width: 3 },
          symbolSize: 10,
          symbol: ["diamond", "diamond"],
          label: {
            fontWeight: "bold",
          },
        },
      };

      this.opts.series.push(series);
    },

    setAlarmSeries() {
      if (this.boundAlarm == null) return;

      if (this.boundAlarm.limitHigh) {
        this.alarmHigh = {
          name: i18n.t("alarm.fields.limitHigh") + " - " + this.boundAlarm.name,
          type: "line",
          data: [],
          itemStyle: { color: "#b71c1c" },
          markLine: {
            data: [
              {
                yAxis: this.boundAlarm.limitHigh,
              },
            ],
            silent: false,
            lineStyle: { width: 3 },
            symbolSize: 10,
            symbol: ["triangle", "triangle"],
            label: {
              fontWeight: "bold",
            },
          },
        };
      }

      if (this.boundAlarm.limitLow) {
        this.alarmLow = {
          name: i18n.t("alarm.fields.limitLow") + " - " + this.boundAlarm.name,
          type: "line",
          data: [],
          itemStyle: { color: "#0d47a1" },
          markLine: {
            data: [
              {
                yAxis: this.boundAlarm.limitLow,
              },
            ],
            silent: false,
            lineStyle: { width: 3 },
            symbolSize: 10,
            symbol: ["triangle", "triangle"],
            label: {
              fontWeight: "bold",
            },
          },
        };
      }

      this.opts.series.push(this.alarmHigh);
      this.opts.series.push(this.alarmLow);
    },

    updateColors() {
      let valueSpan = this.opts.yAxis.max - this.opts.yAxis.min;

      if (this.module.meta != null) {
        let styleMetas = this.module.meta.filter((m) =>
          m.key.startsWith("so_style_meta")
        );

        if (styleMetas.length > 0) {
          styleMetas.sort(
            (sm1, sm2) => parseInt(sm1.valueMatch) - parseInt(sm2.valueMatch)
          );

          for (let meta of styleMetas) {
            if (
              meta.valueMatch != null &&
              this.opts.series[0].data[0] < valueSpan * (meta.valueMatch / 100)
            ) {
              this.opts.series[0].itemStyle.color = meta.content;
              break;
            }
          }
        }
      }
    },

    setFakeData() {
      this.opts.series[0].data = ["60"];
      this.opts.xAxis.data = ["Node 1 - Sensor 1"];

      this.opts.yAxis.min = 0;
      this.opts.yAxis.max = 100;
      this.updateColors();
    },
  },

  async created() {
    if (!this.fakeData) {
      await this.getKeyLabel({
        tagId: this.module.tag.deveui,
        key: this.module.key,
      });
      this.keyLabel = this.currentLabel;

      await this.updateData();

      this.updateColors();

      let alarmMeta = await this.getMeta(this.module.meta, Meta.Enum.ALARM_ID);
      if (alarmMeta) {
        await this.getAlarmById(alarmMeta.valueMatch);

        this.boundAlarm = this.currentAlarm;

        if (this.boundAlarm != null) this.setAlarmSeries();
      }

      let targetMeta = await this.getMeta(this.module.meta, Meta.Enum.TARGET);
      if (targetMeta) this.setTargetSeries(targetMeta.valueMatch);
    } else {
      this.setFakeData();
    }
  },

  components: {},

  watch: {
    async timerTick(v) {
      if (v % 3 == 0) {
        await this.updateData();
      }
    },

    module() {
      if (this.fakeData && this.module.meta && this.module.meta.length > 0) {
        this.setFakeData();
      }
    },
  },
};
</script>