<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ headline }}</span>
    </v-card-title>

    <v-form ref="moduleForm" v-model="valid" :lazy-validation="false">
      <v-card-text v-if="!edit">
        <v-icon style="margin-right: 0.3rem"
          >mdi-format-list-bulleted-type</v-icon
        ><span style="font-size: 1rem; font-weight: bold">
          {{ getTypeName(type) }}
        </span>
        <v-icon large color="primary" @click="$emit('change-type-event')"
          >mdi-pen</v-icon
        >
      </v-card-text>
      <v-card-text>
        <v-text-field
          :label="$t('module.fields.title')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="form.title"
          :rules="reqRule"
        ></v-text-field>
      </v-card-text>

      <v-textarea
        v-if="isType(MType.INFOCARD, type)"
        :label="$t('module.fields.content')"
        prepend-icon="mdi-note-text-outline"
        type="text"
        v-model="form.content"
        :rules="reqRule"
        class="ma-0 pa-4"
        outlined
      ></v-textarea>

      <TextEditorModule
        v-if="
          isType(MType.TEXT, type) &&
          form.content != undefined &&
          form.content != null
        "
        v-on:update-html="updateContent"
        :content="form.content"
      ></TextEditorModule>

      <div
        v-if="
          isType(
            [
              MType.CHART,
              MType.GAUGE,
              MType.WEATHER,
              MType.SINGLE,
              MType.RAWTABLE,
              MType.INFOCARD,
              MType.TRENDCHART,
              MType.TILTCHART,
              MType.FILLINDICATOR,
            ],
            type
          )
        "
      >
        <v-card-text>
          <v-autocomplete
            autocomplete="off"
            :loading="tagStatus.loading"
            :label="$t('alarm.fields.tag')"
            v-model="deveui"
            :items="tags"
            :rules="autocompleteRules"
            required
            item-text="name"
            item-value="deveui"
            prepend-icon="mdi-cellphone-link"
          ></v-autocomplete>
        </v-card-text>
      </div>

      <div
        v-if="
          isType(
            [
              MType.CHART,
              MType.GAUGE,
              MType.SINGLE,
              MType.TRENDCHART,
              MType.FILLINDICATOR,
            ],
            type
          )
        "
      >
        <v-card-text>
          <v-autocomplete
            autocomplete="off"
            :disabled="tagStatus.loading || disabledKeys"
            :loading="tagDataStatus.loading"
            :label="$t('alarm.fields.tagDataKey')"
            v-model="form.tagKey"
            :items="keys"
            :rules="autocompleteRules"
            required
            item-text="label"
            item-value="key"
            prepend-icon="mdi-key"
          ></v-autocomplete>
        </v-card-text>
      </div>

      <div v-if="isType([MType.GAUGE, MType.FILLINDICATOR], type)">
        <v-row
          style="max-width: 100% !important"
          class="ma-0"
          align="center"
          justify="center"
        >
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :label="$t('module.fields.min')"
              type="number"
              v-model="form.gaugeMin"
              single-line
              prepend-icon="mdi-priority-low"
              :rules="reqRule"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              :label="$t('module.fields.max')"
              type="number"
              v-model="form.gaugeMax"
              single-line
              prepend-icon="mdi-priority-high"
              :rules="reqRule"
            />
          </v-col>
        </v-row>
      </div>

      <v-card-text v-if="isType(MType.IFRAME, type)">
        <v-text-field
          :label="$t('module.fields.url')"
          prepend-icon="url"
          type="text"
          v-model="url"
          :rules="reqRule"
        ></v-text-field>
      </v-card-text>

      <div v-if="isType([MType.IMAGE, MType.IMAGEMAP], type)">
        <v-card-text class="text-center">
          <v-file-input
            center
            :label="$t('module.fields.image')"
            v-model="form.image"
          />
        </v-card-text>
      </div>

      <function-button-form
        v-if="isType(MType.FUNCTIONBUTTON, type)"
        :module="module"
        :form="form"
        :type="type"
        ref="functionButtonForm"
        v-model="functionButtonPayload"
        v-on:model-update="(v) => (functionButtonPayload = v)"
      >
      </function-button-form>

      <!--If conditions for showing meta is met we show the meta add form-->
      <meta-form
        v-if="formSetupComplete"
        :formmeta="form.metas"
        :type="type"
        v-on:update-meta="updateMeta"
        :deveui="form.deveui"
        :tagKey="form.tagKey"
        :indexedTags="indexedTags"
      />
    </v-form>

    <v-card-actions>
      <v-btn color="error darken-1" v-if="edit" text @click="remove()">{{
        $t("common.delete")
      }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close()">{{
        $t("common.close")
      }}</v-btn>
      <v-btn color="blue darken-1" v-if="edit" text @click="submit()">{{
        $t("common.update")
      }}</v-btn>

      <v-btn color="blue darken-1" v-if="!edit" text @click="submit()">{{
        $t("common.create")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Enum from "@/_helpers/Enum";
import CsharpEnum from "@/_helpers/CsharpEnum";
import ModuleTypes from "@/_helpers/ModuleTypeHelper.js";
import MetaForm from "@/components/module/MetaForm";
import FunctionButtonForm from "@/components/module/FunctionButtonForm";
import TextEditorModule from "@/components/common/TextEditorModule";
import Meta from "@/_helpers/ModuleMeta";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModuleForm",

  props: {
    headline: { default: "", require: true },
    edit: { default: false },
    styleMetas: { default: [], require: true },
    module: { default: {}, require: true },
    type: { default: null, require: true },
  },

  data() {
    return {
      valid: false,
      deveui: "",
      form: {
        title: "",
        content: "",
        gaugeMin: null,
        gaugeMax: null,
        deveui: "",
        tagKey: "",
        image: null,
        metas: [],
        styleMetas: [],
      },

      functionButtonPayload: {},

      url: null,
      disabledKeys: false,
      formSetupComplete: false,
      moduleTypes: ModuleTypes.types,

      sm: Enum.ModuleSize.LG,
      md: Enum.ModuleSize.LG,

      indexedTags: [],

      autocompleteRules: [
        (v) => !!v || v === 0 || this.$t("alarm.validation.nameRequired"),
      ],

      reqRule: [
        (v) => !!v || v === 0 || this.$t("alarm.validation.nameRequired"),
      ],
    };
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),
    ...mapActions("modules", [
      "delete",
      "update",
      "create",
      "postImage",
      "getModule",
    ]),

    updateContent(newContent) {
      this.form.content = newContent;
    },

    updateMeta(metas) {
      this.form.metas = metas.concat(this.styleMetas);
    },

    close() {
      this.$emit("close-dialog-event");
    },

    async remove() {
      await this.delete({ moduleId: this.module.dashboardModuleId });
      this.dialog = false;
      this.$router.go();
    },

    async submit() {
      if (!this.$refs.moduleForm.validate()) {
        this.valid = false;
        return;
      }

      if (this.styleMetas.length > 0) {
        this.form.metas = this.form.metas.filter(
          (m) => !m.key.startsWith("so_style_meta")
        );
      }
      const data = {
        title: this.form.title,
        content: this.form.content,
        min: this.form.gaugeMin,
        max: this.form.gaugeMax,
        type: this.form.type,
        sm: this.form.sm,
        md: this.form.md,
        deveui: this.form.deveui,
        key: this.form.tagKey,
        meta: this.form.metas.concat(this.styleMetas),
      };

      if (this.type === CsharpEnum.ModuleType.FUNCTIONBUTTON) {
        data.meta = data.meta.filter(
          (e) =>
            e.key !== "so_dashboard_id" &&
            e.key !== "so_script_id" &&
            e.key !== Meta.Enum.DOWNLINK &&
            e.key !== Meta.Enum.ONOFFSWITCH
        );

        data.deveui = this.functionButtonPayload.deveui;
        data.key = this.functionButtonPayload.tagKey;

        if (
          this.functionButtonPayload.functionType ===
          CsharpEnum.FunctionTypes.SCRIPT
        ) {
          data.meta.push({
            key: "so_script_id",
            value: this.functionButtonPayload.script,
          });
        }

        if (
          this.functionButtonPayload.functionType ===
          CsharpEnum.FunctionTypes.DASHBOARD
        ) {
          data.meta.push({
            key: "so_dashboard_id",
            value: this.functionButtonPayload.dashboard,
          });
        }

        if (
          this.functionButtonPayload.functionType ===
          CsharpEnum.FunctionTypes.ONOFFSWITCH
        ) {
          data.meta.push({
            key: "so_functionbutton_onoff",
            value: null,
          });
        } else if (
          this.functionButtonPayload.functionType !==
          CsharpEnum.FunctionTypes.VALUEINPUT
        ) {
          data.deveui = null;
          data.key = null;
        }

        if (
          this.functionButtonPayload.functionType ===
          CsharpEnum.FunctionTypes.DOWNLINK
        ) {
          data.deveui = this.functionButtonPayload.deveui;
          data.meta.push({
            key: Meta.Enum.DOWNLINK,
            value: this.functionButtonPayload.downlinkPayload,
            valueMatch: this.functionButtonPayload.downlinkPort,
            content: this.functionButtonPayload.downlinkConfirmed,
          });
        }
      }

      if (this.type === CsharpEnum.ModuleType.IFRAME) {
        data.content = this.url;
      }

      if (this.type === CsharpEnum.ModuleType.MULTICHART) {
        if (this.syncChart) data.content = 1;
        else data.content = 0;
      }

      if (this.edit) {
        data.index = this.module.index;
        data.x = this.module.x;
        data.y = this.module.y;
        data.w = this.module.w;
        data.h = this.module.h;

        await this.update({
          moduleId: this.module.dashboardModuleId,
          payload: data,
        });

        this.$emit("update-message-event", this.$t("common.updatesPending"));
      } else {
        data.w = 5;
        data.h = 8;

        let dashboardId = this.$route.params.id;
        if (!dashboardId) dashboardId = this.currentDashboard.dashboardId;

        await this.create({
          dashboardId: dashboardId,
          payload: data,
        });
      }

      if (
        this.form.image !== null &&
        (this.currentModule.type === CsharpEnum.ModuleType.IMAGE ||
          this.currentModule.type === CsharpEnum.ModuleType.IMAGEMAP)
      ) {
        let formData = new FormData();
        formData.append("image", this.form.image);

        if (!this.currentModule.dashboardModuleId)
          this.currentModule.dashboardModuleId = this.module.dashboardModuleId;

        this.postImage({
          moduleId: this.currentModule.dashboardModuleId,
          image: formData,
        });

        this.$emit("update-message-event", this.$t("common.updatesPending"));
      }

      if (!this.edit) {
        if (this.$refs.moduleForm) this.$refs.moduleForm.reset();
        this.$emit("created-event");
      }

      this.$emit("close-dialog-event");
    },

    setupFormCreate() {
      this.$emit("update-form", this.form);
      this.form.type = this.type;
      this.formSetupComplete = true;
    },

    async setupFormEdit() {
      this.form = {
        title: this.module.title,
        content: this.module.content,
        gaugeMin: this.module.min,
        gaugeMax: this.module.max,
        type: this.module.type,
        sm: this.module.sm,
        md: this.module.md,
        tagKey: this.module.key,
        metas: [],
        styleMetas: [],
      };

      if (this.module.tag !== null) {
        this.form.deveui = this.module.tag.deveui;
        this.deveui = this.module.tag.deveui;
      }

      if (this.type === CsharpEnum.ModuleType.IFRAME) {
        this.url = this.module.content;
        this.form.content = "";
      }

      if (this.type === CsharpEnum.ModuleType.MULTICHART) {
        if (this.form.content == 1) this.syncChart = true;
        else this.syncChart = false;
      }

      if (this.module.meta !== null) {
        this.form.metas = this.module.meta;
      }

      this.formSetupComplete = true;
      this.$emit("update-form", this.form);
    },

    indexTags() {
      let tempIndex = {};
      for (let tag of this.tags) {
        tempIndex[tag.deveui] = tag.name;
      }
      this.indexedTags = tempIndex;
    },

    isType(target, type) {
      if (!Array.isArray(target)) {
        return target === type;
      }

      return target.includes(type);
    },

    getTypeName(type) {
      for (let i in this.moduleTypes) {
        if (this.moduleTypes[i].value == type) return this.moduleTypes[i].name;
      }

      return "";
    },
  },

  async created() {
    await this.getTags();
    this.indexTags();

    if (this.edit) {
      await this.setupFormEdit();
    } else {
      this.setupFormCreate();
    }
    if (this.metaStyles) this.form.styleMetas = this.metaStyles;
  },

  computed: {
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("dashboards", ["dashboards", "currentDashboard"]),
    ...mapState("modules", { currentModule: "currentModule" }),

    MType() {
      return CsharpEnum.ModuleType;
    },
  },

  watch: {
    // eslint-disable-next-line
    async deveui(val, oldVal) {
      if (val != oldVal) {
        this.form.deveui = val;

        if (val) {
          await this.getKeysForTag({ tagId: val });
          this.disabledKeys = this.keyDisabled;
        }

        if (oldVal) this.metas = [];
      }
    },

    form(val) {
      this.$emit("update-form", val);
    },
  },

  components: {
    MetaForm,
    TextEditorModule,
    FunctionButtonForm,
  },
};
</script>
