var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding":"0","height":"100%"},attrs:{"fluid":""}},[_c('v-card',{style:('height: 100%; overflow: hidden; z-index: -10;' +
      (_vm.soMetaBackgroundColorCard
        ? 'background-color: ' + _vm.soMetaBackgroundColorCard
        : ''))},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-toolbar',{style:('background: transparent;' +
          (_vm.soMetaTextColorTitle ? 'color: ' + _vm.soMetaTextColorTitle : '')),attrs:{"dense":"","flat":""}},[_c('v-toolbar-items',{staticClass:"text-left",staticStyle:{"flex-direction":"column"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.module.title))]),_c('v-spacer'),(!_vm.fakeData && _vm.loaded)?_c('span',{staticStyle:{"width":"100%","font-size":"0.8rem"}},[_vm._v(_vm._s(_vm.humanDate(this.dataValue.createdAt)))]):_vm._e()],1)],1)],1),_c('v-row',{staticStyle:{"max-height":"80%","height":"80%","width":"100%","max-width":"100%","margin":"0"}},[(_vm.loaded)?_c('e-chart',{staticClass:"px-2",style:('z-index: -9;' +
          (_vm.fakeData
            ? 'width: 100%; height: 250px; overflow: hidden'
            : _vm.gauge180
            ? 'width: 100%; height: 160%; overflow: hidden'
            : 'width: 100%; height: 100%; overflow: hidden')),attrs:{"option":_vm.opts,"autoresize":true,"theme":this.darkmode ? 'sensoronlinedark' : 'macarons'}}):_vm._e()],1),_c('v-row',{staticStyle:{"padding-bottom":"1rem"},attrs:{"no-gutters":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }