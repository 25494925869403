<template>
  <v-card
    :style="
      'height: 100%; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
  >
    <v-row no-gutters>
      <v-toolbar
        dense
        flat
        :style="
          'background: transparent;' +
          (soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : '')
        "
      >
        <v-toolbar-title>{{ module.title }}</v-toolbar-title>
      </v-toolbar>
    </v-row>
    <v-row no-gutters style="height: 80%">
      <e-chart
        v-if="loaded"
        :option="opts"
        style="width: 100%; max-height: 100%; height: 100% padding-top: 1rem"
        :autoresize="true"
        :theme="this.darkmode ? 'sensoronlinedark' : 'macarons'"
      />
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "TiltChart",

  props: ["module", "loading", "timerTick", "fakeData"],

  mixins: [Meta],

  data() {
    return {
      loaded: false,
      dataX: null,
      dataY: null,
      maxDataX: null,
      maxDataY: null,
      opts: {
        legend: {
          data: [this.module.tag.name],
          top: "-5",
        },
        polar: {},
        tooltip: {
          formatter: "{a}: <br /> {c0}",
          snap: true,
          axisPointer: {
            type: "cross",
          },
        },
        angleAxis: {
          type: "value",
          startAngle: 90,
          max: 360,
          axisLabel: {
            formatter: "{value}°",
          },
        },
        radiusAxis: {
          axisLabel: {
            formatter: "{value}°",
            fontWeight: "bold",
          },
        },
        series: [
          {
            coordinateSystem: "polar",
            name: this.module.tag.name,
            type: "line",
            areaStyle: { opacity: 0.3 },
            itemStyle: {},
            data: [],
          },
        ],
      },
    };
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
    ...mapState("tagData", ["currentTagData"]),
  },

  methods: {
    ...mapActions("tagData", { getData: "getCurrentTagdata" }),

    async loadData() {
      // Retrieve current data for X and Y
      let xMeta = this.getMeta(this.module.meta, Meta.Enum.TAGKEY_X);

      if (xMeta) {
        await this.getData({
          tagId: this.module.tag.deveui,
          tagKey: xMeta.valueMatch,
        });

        if (this.currentTagData)
          this.dataX = parseFloat(this.currentTagData.value);
      }

      let yMeta = this.getMeta(this.module.meta, Meta.Enum.TAGKEY_Y);

      if (yMeta) {
        await this.getData({
          tagId: this.module.tag.deveui,
          tagKey: yMeta.valueMatch,
        });

        if (this.currentTagData)
          this.dataY = parseFloat(this.currentTagData.value);
      }

      // Add the data to the graph
      this.opts.series[0].data.push([0, 0]);
      if (this.dataX)
        this.opts.series[0].data.push([
          Math.abs(this.dataX),
          this.dataX > 0 ? 90 : 270,
        ]);
      if (this.dataY)
        this.opts.series[0].data.push([
          Math.abs(this.dataY),
          this.dataY > 0 ? 0 : 180,
        ]);
      this.opts.series[0].data.push([0, 0]);

      // Check if max values are enabled
      let xMaxKey = this.getMeta(this.module.meta, Meta.Enum.TAGKEY_X_MAX);
      let yMaxKey = this.getMeta(this.module.meta, Meta.Enum.TAGKEY_Y_MAX);

      if (yMaxKey && xMaxKey) this.addMaxData(xMaxKey, yMaxKey);
    },

    async addMaxData(xKey, yKey) {
      // Add a new series for the max value display
      if (this.opts.series.length < 2) {
        this.opts.series.push({
          coordinateSystem: "polar",
          name: this.module.tag.name + " MAX",
          type: "line",
          areaStyle: { opacity: 0.3 },
          z: 1,
          data: [],
        });
        // Add the new series to the legend
        this.opts.legend.data.push(this.module.tag.name + " MAX");
      }

      // Retrieve max value keys latest data
      await this.getData({
        tagId: this.module.tag.deveui,
        tagKey: xKey.valueMatch,
      });

      if (this.currentTagData)
        this.maxDataX = parseFloat(this.currentTagData.value);

      await this.getData({
        tagId: this.module.tag.deveui,
        tagKey: yKey.valueMatch,
      });

      if (this.currentTagData)
        this.maxDataY = parseFloat(this.currentTagData.value);

      // Add max value data to the new series
      this.opts.series[1].data.push([0, 0]);
      this.opts.series[1].data.push([
        Math.abs(this.maxDataX),
        this.maxDataX > 0 ? 90 : 270,
      ]);
      this.opts.series[1].data.push([
        Math.abs(this.maxDataY),
        this.maxDataY > 0 ? 0 : 180,
      ]);
      this.opts.series[1].data.push([0, 0]);
    },

    updateColors() {
      if (!this.module.meta) return;
      let styleMetas = this.module.meta.filter((m) =>
        m.key.startsWith("so_style_meta")
      );

      let seriesIndex = 0;
      for (let meta of styleMetas) {
        if (meta.key == "so_style_meta_color" && seriesIndex < 2) {
          this.opts.series[seriesIndex].itemStyle = {};
          this.opts.series[seriesIndex].itemStyle.color = meta.content;
          seriesIndex++;
        }
      }
    },

    async runPreview() {
      this.opts.series[0].data.push([0, 0]);
      this.opts.series[0].data.push([5, 90]);
      this.opts.series[0].data.push([10, 0]);
      this.opts.series[0].data.push([0, 0]);

      if (this.opts.series.length < 2) {
        this.opts.series.push({
          coordinateSystem: "polar",
          name: this.module.tag.name + " MAX",
          type: "line",
          areaStyle: { opacity: 0.3 },
          itemStyle: {},
          z: 1,
          data: [],
        });
        this.opts.legend.data.push(this.module.tag.name + " MAX");
      }

      this.opts.series[1].data.push([0, 0]);
      this.opts.series[1].data.push([10, 90]);
      this.opts.series[1].data.push([20, 0]);
      this.opts.series[1].data.push([0, 0]);
    },

    clearData() {
      this.opts.series[0].data = [];
      if (this.opts.series.length > 1) this.opts.series[1].data = [];
    },
  },

  async mounted() {
    if (!this.fakeData) await this.loadData();
    else this.runPreview();
    this.updateColors();
    this.loaded = true;
  },

  watch: {
    timerTick(v) {
      if (v % 6 == 0 && !this.fakeData) {
        this.clearData();
        this.loadData();
      }
    },
    module() {
      if (this.fakeData) {
        this.updateColors();
      }
    },
  },
};
</script>