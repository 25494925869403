import CsharpEnum from "@/_helpers/CsharpEnum";
import Enum from "@/_helpers/Enum.js";
import i18n from "@/plugins/i18n.js";

export default {
  types: [
    {
      name: i18n.t("module.enum.types.0"),
      value: CsharpEnum.ModuleType.MULTICHART,
      icon: "mdi-chart-areaspline",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.14"),
      value: CsharpEnum.ModuleType.TRENDCHART,
      icon: "mdi-chart-bar-stacked",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.1"),
      value: CsharpEnum.ModuleType.GAUGE,
      icon: "mdi-gauge",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.16"),
      value: CsharpEnum.ModuleType.TILTCHART,
      icon: "mdi-rotate-orbit",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.8"),
      value: CsharpEnum.ModuleType.SINGLE,
      icon: "mdi-counter",
      colorFieldsUsed: [false, false, false, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.2"),
      value: CsharpEnum.ModuleType.TEXT,
      icon: "mdi-card-text",
      colorFieldsUsed: [false, false, false, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.4"),
      value: CsharpEnum.ModuleType.MAP,
      icon: "mdi-map-marker-multiple",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.11"),
      value: CsharpEnum.ModuleType.IMAGEMAP,
      icon: "mdi-home-map-marker",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.3"),
      value: CsharpEnum.ModuleType.IMAGE,
      icon: "mdi-image",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.6"),
      value: CsharpEnum.ModuleType.IFRAME,
      icon: "mdi-iframe",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.7"),
      value: CsharpEnum.ModuleType.WEATHER,
      icon: "mdi-weather-cloudy",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.9"),
      value: CsharpEnum.ModuleType.RAWTABLE,
      icon: "mdi-table-of-contents",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.12"),
      value: CsharpEnum.ModuleType.FUNCTIONBUTTON,
      icon: "mdi-language-javascript",
      colorFieldsUsed: [true, true, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.13"),
      value: CsharpEnum.ModuleType.INFOCARD,
      icon: "mdi-information",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.15"),
      value: CsharpEnum.ModuleType.SENSOROVERVIEW,
      icon: "mdi-leak",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: false,
    },
    {
      name: i18n.t("module.enum.types.17"),
      value: CsharpEnum.ModuleType.FILLINDICATOR,
      icon: "mdi-cup",
      colorFieldsUsed: [true, false, true, false, false],
      hasOwnStyle: true,
    },
    {
      name: i18n.t("module.enum.types.18"),
      value: CsharpEnum.ModuleType.CONTROLSLIDERS,
      icon: "mdi-tune",
      colorFieldsUsed: [false, false, false, false, false],
      hasOwnStyle: false,
    },
  ],

  colorFieldsMeta: [
    {
      name: i18n.t("module.meta.styles.color.cardBackground"),
      value: "so_style_meta_color_background_card",
    },
    {
      name: i18n.t("module.meta.styles.color.titleBackground"),
      value: "so_style_meta_color_background_title",
    },
    {
      name: i18n.t("module.meta.styles.color.titleText"),
      value: "so_style_meta_color_text_title",
    },
    {
      name: i18n.t("module.meta.styles.color.valueBackground"),
      value: "so_style_meta_color_background_value",
    },
    {
      name: i18n.t("module.meta.styles.color.valueText"),
      value: "so_style_meta_color_text_value",
    },
  ],

  methods: {
    getDefaultSizeForWidget(type) {
      return {
        sm: Enum.SizeForType[type].sm[0].value,
        md: Enum.SizeForType[type].md[0].value,
      };
    },

    isInType(t, types) {
      if (typeof types != "object" && types.length < 0) return false;

      return types.includes(t);
    },
  },

  computed: {
    ModuleTypes() {
      return CsharpEnum.ModuleType;
    },

    TextStyleFieldNames() {
      return [
        {
          name: this.$t("module.meta.styles.text.titleBackground"),
          value: "so_style_meta_text_title_background",
        },
        {
          name: this.$t("module.meta.styles.text.background"),
          value: "so_style_meta_text_background",
        },
        {
          name: this.$t("module.meta.styles.text.textcolor"),
          value: "so_style_meta_text_textcolor",
        },
      ];
    },

    SingleStyleFieldNames() {
      return [
        {
          name: this.$t("module.meta.styles.single.titleBackground"),
          value: "so_style_meta_singel_title_background",
        },
        {
          name: this.$t("module.meta.styles.single.background"),
          value: "so_style_meta_singel_background",
        },
        {
          name: this.$t("module.meta.styles.single.textcolor"),
          value: "so_style_meta_singel_textcolor",
        },
        {
          name: this.$t("module.meta.styles.single.sparkline"),
          value: "so_style_meta_single_sparkline_color",
        },
        {
          name: this.$t("module.meta.styles.single.sparklineLabel"),
          value: "so_style_meta_single_sparkline_label_color",
        },
      ];
    },
  },
};
