<template>
  <div class="overlay" style="right: 0px; bottom: 0px; position: absolute">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          small
          color="accent"
          v-on="on"
          @click.stop="copyModuleEvent"
          :class="
            module.h <= 3
              ? 'dashboard-floaty-copy-slim'
              : 'dashboard-floaty-copy'
          "
          :disabled="disabled"
        >
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <span> {{ $t("dashboard.copy.tooltip") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ModuleCopy",

  props: {
    active: {
      default: false,
    },

    module: {
      default: null,
    },
  },

  data() {
    return {
      disabled: false,
    };
  },

  methods: {
    ...mapActions("modules", ["copyModule"]),

    async copyModuleEvent() {
      if (this.module == null || !this.module.dashboardModuleId) return;

      await this.copyModule(this.module.dashboardModuleId);
      this.$emit("update", this.$t("common.updatesPending"));

      this.disabled = true;

      setTimeout(() => {
        this.disabled = false;
      }, 1000);
    },
  },
};
</script>

<style>
.dashboard-floaty-copy {
  position: absolute;
  margin: 0 auto;
  z-index: 1;
  transform: translateX(-60px) translateY(-120px);
}

.dashboard-floaty-copy-slim {
  position: absolute;
  margin: 0 auto;
  transform: translateX(-120px) translateY(-60px);
}
</style>